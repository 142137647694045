import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons'

const IntroductionSection = () => {
    return (
        <div className="relative h-[90vh] bg-fixed bg-cover bg-center" style={{ backgroundImage: "url('/IMG_1404.png')" }}>
            <div className="absolute inset-0 bg-black opacity-10"></div>
            <div className="relative flex justify-center items-center h-full">
                <div className={`text-white text-center ${global.config.settings.main.content_width}`}>
                    <div className="flex justify-center items-center bg-white">
                        <div className="w-1/2">
                            <div className=" h-[40vh]"></div>
                        </div>
                        <div className="w-1/2 space-y-2 p-2">
                            <h1 className="text-3xl text-gray-900 ml-5">{global.config.about.name}</h1>
                            <p className="text-gray-700 ml-5 font-semibold text-sm">
                                {global.config.about.institution}
                                <br />
                                {global.config.about.degree}
                            </p>
                        </div>
                    </div>
                    <div className="flex justify-center items-center bg-primary p-4 text-white font-extrabold space-x-5">
                        <div>
                            <a href={global.config.about.social.linkedin.link} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} className="pr-2" />
                                {global.config.about.social.linkedin.name}
                            </a>
                        </div>
                        <div>
                            <a href={global.config.about.social.github.link} target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faGithub} className="pr-2" />
                                {global.config.about.social.github.name}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IntroductionSection