// npm install --save @fortawesome/react-fontawesome

module.exports = global.config = {
    about: {
        name: "Oliver Toparti",
        institution: "Imperial College London",
        degree: "MSc Security and Resilience '25",
        social: {
            github: {
                name: "GitHub",
                link: "https://github.com/olivertoparti",
            },
            linkedin: {
                name: "LinkedIn",
                link: "https://www.linkedin.com/in/toparti/",
            },
            email: {
                name: "Email",
                link: "oliver.toparti@imperial.ac.uk",
            }
        }
    },
    settings: {
        main: {
            margin: "m-0 md:mx-5",
            content_width: "w-full md:w-4/5 lg:w-3/5 xl:w-3/5",
        },
        blog: {
            sources: {
                idcd: {
                    name: "IDCD",
                    link: "https://idcd.org.uk/",
                    api: "https://blog.idcd.org.uk/",
                },
                imperial: {
                    name: "Imperial College London",
                    link: "https://www.imperial.ac.uk/",
                    api: "https://blogs.imperial.ac.uk/toparti/",
                },
            },
        },
    },
};